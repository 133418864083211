.pe-template__static-medium div.search-modal {
    width: 500px !important;
    height: 60% !important;
  }
.pe-template__static-medium div.search-modal .pe-btn__primary   {
    margin-bottom: 20px;    
}
button:focus {
    outline: 0 !important;
    outline: none !important;
}
  
.modalOverlay{
    padding-top: 105px !important;
}
.modalBody{    
    overflow:none !important;   
}
@media (min-width: 1280px){
.modalBody_border_normal{
    
    overflow-y:hidden !important;
}
}
@media (min-width: 1280px){
    .modalBody{
        
        overflow-y:hidden !important;
    }
    }
.textCenter{
    text-align: center;
}