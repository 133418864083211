.pe-template__static-medium {
  width: 500px !important;
}

.team-member-selected,
.team-member-selected:hover{
    cursor: default;
    background-color:#daf0ed;
    border-top:1px solid #19a6a4;
    border-bottom:1px solid #19a6a4;
    border-collapse: collapse;
  }

.team-member-not-selected:hover{ 
    cursor: pointer;
    background-color:lightgray;
    border-top:1px solid #d0d6d6;
    border-bottom:1px solid #bfc0c0;
    border-collapse: collapse;
  }