.pe-template__static-medium {
    width: 1175px !important;
}
.modalOverlay{
    padding-top: 40px !important;
}
.modalBody{
    
    overflow:none !important;
}
.pe-table-toolbar {
    margin-top: 13px !important;
}
@media (min-width: 1280px){
.modalBody_border_normal{
    
    overflow-y:hidden !important;
}
}
@media (min-width: 1280px){
    .modalBody{
        
        overflow-y:hidden !important;
    }
    }
.textCenter{
    text-align: center;
}
.pe-table-toolbar{
    margin-top: 13px;
}
.modalOverlay .pe-template__single{
    padding-top: 18px !important;
}