body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
*:focus{
  outline: 0 !important;
  outline: none !important;
}

a:focus {
  outline: 0 !important;
  outline: none !important;
}
button:focus {
  outline: 0 !important;
  outline: none !important;
}

.app {
  margin: 110px auto 0px auto;
  min-width: 550px;
  max-width: 1024px;
  height: 100%;
}
.noData{
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  padding: 30px 0px 15px 0px;
}

.test {
  z-index: -1;
  position: absolute;
  display: block;
  background-color: #f5f5f5;
  width: 100%;
  height: 100px;
}

.subNav {
  margin-top: 70px;
  margin-bottom: 30px;
}
#disableSuccessBtn{display: none;}
.searchBox{    float: left;
  width: 60%;}

.searchButton{    float: left;
  width: 34%;
  padding-left: 3%;
  padding-top: 3.5%;
  }

.pe-template__static-medium{width:860px !important;}

.barStyleTabs {
  top: 0x;
  background-color: #005a70;
  height: 40px;
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  padding-left: 20px;
  position: absolute;
  z-index: 99;
}
.barStyleText {  
  background-color: #005a70;      
  margin: 7px 30px 0px 0px;  
  color: whitesmoke;
  /* font: 160px sans-serif normal; */
}
.scrollTable{
  overflow-y: scroll;
  height: 200px;
}

.barStyleTabs .tabs__labels {
  position: absolute;
  bottom: 0;
}

.pull-right {
  float: right !important;
}

.modal-margin-top{
  float: right !important;
  margin-top: 9%;
}
.pull-left {
  float: left !important;
}

.clearfix {
  display: block;
  clear: both;
}

.groupIcon {
  position: relative;
  display: inline-block;
  padding-left: 5px;
  height: 15px;
}

.breadcrumb-group {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.breadcrumb svg {
  color: #6a7070;
  padding: 0px 4px 0px 4px;
}

.breadcrumb a.active,
.breadcrumb a:active,
.breadcrumb a:focus,
.breadcrumb a:hover {
  color: #047a9c;
}
.pe-table-toolbar {
  margin-top: 28px;
}

/* .pe-table-toolbar .pe-btn {
  color: #6a7070;
  background-color: #e9e9e9;
  border-color: #c7c7c7;
  box-shadow: none;
  text-decoration: none;  
}                     */

.content {
  padding-top: 28px;
  margin-bottom:5rem;
}

hr {
  border-top: 1px solid #e9e9e9;
  color: #e9e9e9;
  margin-bottom: 20px;
  height: 0px;
}

.text-center {
  text-align: center;
}

.text-red {
  color: red;
  font-size: smaller;
}
.warning-box { height: 5px; }

div.content section div.selectAAWarning span.selectAAWarningIcon {
  padding-left: 20px;
  padding-right: 12px;
}

div.content section div.selectAAWarning span.selectAAWarningText {
  white-space: nowrap;
}

div.content section div.selectAAWarning {
  background-color: #fff;
  border: 1px solid #c7c7c7;
  border-left:3px solid #DB0020;
  font-size: 14px;
  font-weight: 600;
  height: 70px;
  padding: 24px 30px 24px 0px;
  box-shadow: 0px 1px 1px 0px rgba(0,0,0, .16);  
  line-height: 18px;
  max-width: 248px;
}

.tooltip {
  position: relative;
  display: inline-block;
  
}

.tooltip .tooltiptext-sample-recall-failed {
  visibility: hidden;
  width: 299px;
  background-color: #696969;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: -40px;
  right: 137%;
}

.pe-icon--warning-sm-18{color:#DB0020}

.tooltip .tooltiptext-sample-recall-failed::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #696969;
}

.tooltip:hover .tooltiptext, .tooltip:hover .tooltiptext-sample-recall-failed{
  visibility: visible;
}

.small-gap
{
  margin-bottom: 10px;
}
/*** 
To style pearson element TableHeaderCell button 
when raw <table> tag is used
***/
table.pe-table--sortable th button{
	box-sizing: border-box;
	width: 100%;
	border: 0;
	background-color: transparent;
	padding: $pe-table-paddings;
	text-align: left;
	cursor: pointer;
	font-weight: 600;
}

table.pe-table--sortable th button:focus{
	outline: none;
}
table.pe-table--sortable thead tr th{
	font-weight:600;

}
.rowHighlight {
  /* background-color: #e9e9e9; */
  outline: 1px dotted #c9c9c9;
}
/* tabs from old pearson elements sdk version */

/* Remove horizontal scrollbar from madal */
@media (min-width: 1280px){
  .modalBody { 
      overflow-x: hidden;
      
  }  
}

/* When team lead viewing members page */
/* when hover rows are READONLY */
.pe-table tbody tr.disabled-selected,
.pe-table tbody tr.disabled-selected:hover, 
.pe-table tbody tr.disabled-selected td{
  cursor: default;
  background-color:#daf0ed;
  border-top:1px solid #19a6a4;
  border-bottom:1px solid #19a6a4;
  border-collapse: collapse;
  
}

.pe-table tbody tr.disabled-not-selected td{
  cursor: default;
}
  .pe-table tbody tr.disabled-not-selected:hover{ 
  cursor: default;
  background-color: #336600;
}
/* when hover rows are READONLY--END */

/*<~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~>*/
/* DROPDOWN MENU CSS */
/*<~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~>*/
/****************************/
.dropdown-menu-right{
  position: relative;
  background: #005a70;
  left: -50px;
  top: 5px;
  color:#505759;
}

.dropdown-menu-right span.dropdown-icon {
  /* margin: 7px 0 0 15px; */
  padding: 8px 0 0 8px;
  cursor: pointer; 
  display: inline-block; 
  -webkit-transition: width 2s;
  transition: width 2s;
}

.dropdown-menu-right span.dropdown-icon:after {
  content: url('data:image/svg+xml; utf8, <svg width="100%" height="16px" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg"><path d="M 50 100 L 350 100 L 200 300 z" fill="whitesmoke" stroke="white" stroke-width="3" /></svg>');
  font-size: 16px;
  /* color: black; */
  /* background-color: black; */
}

.dropdown-menu-right span.dropdown-icon-open {
  /* margin: 7px 0 0 15px; */
  padding: 8px 0 0 8px;
  cursor: pointer; 
  display: inline-block; 
  -webkit-transition: width 2s;
  transition: width 2s;
}

.dropdown-menu-right span.dropdown-icon-open:after {
  content: url('data:image/svg+xml; utf8, <svg width="100%" height="16px" viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg"><path d="M 50,300 L 200,100 L 350,300 z" fill="whitesmoke" stroke="white" stroke-width="3" /></svg>');
  /* background-color: black; */
  font-size: 16px;
}

.dropdown-list-box
{
    display: block;
    min-width:142px;
	  max-width:160px;
    max-height: 140px;
    position: absolute;
    height: auto;    
    background: #ffffff;
    -webkit-border-radius: 1px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: #BCBCBC solid 1px;
    padding: 0;
    font-size:14px;    
    z-index: 10;
    right: -3px;
    top: 28px;
    box-shadow: 2px 2px 3px rgba(182, 184, 186, 0.75);
}
@-moz-document url-prefix(){
  .dropdown-list-box{
    right: -8px;
  }
  .dropdown-selected-option{
    left: 0;
  }
  .dropdown-menu-right span.dropdown-icon-open{
    margin: 0 10px 0 0;
  }
  .dropdown-menu-right span.dropdown-icon{
    margin: 0 10px 0 0;
  }
}
.dropdown-selected-option{
  color: white;
  margin: 0;
  padding: 0;
  position: relative;
  top: -4px;
  cursor: pointer;
}

/***************************/
.link-holder{
	width: auto;
  max-height: 140px;
  overflow: auto;
  scrollbar-width:thin;
  scrollbar-color:#a7a7a7 #e6e6e6;
}
.link-holder ul{
	padding: 0;
	margin: 0;
}
.link-holder ul li{
	list-style: none;
	border-bottom: 1px #CCC solid; /*#DEE7E7*/
  line-height: 18px;
  padding: 5px 0;
  margin: 0;
}
.link-holder ul li:last-child{
	border-bottom:none;
}
.link-holder a{
	color: #555; /* #535657; */
  padding: 3px 10px;
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin: 0;
}
.link-holder a:hover{
	color: #333;
	background: #E2E2E2; 
  /* display: block; */
	/* color: #000; */     /* #0D1F22; */
}

.link-holder span.disabled-link{
  padding: 0 7px;
  color: #CCC;
}
/***************************/
.link-holder::-webkit-scrollbar {
  width: .5em;
}
.link-holder::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.link-holder::-webkit-scrollbar-thumb {
background-color: darkgrey;
outline: 1px solid slategrey;
}
/******************/

/*<~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~>*/
/* 3 DOTS MENU CSS ENDS */
/*<~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~><~>*/

/* Footer Component CSS */
.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding-bottom: 24px;
  background: #fff;
  padding-top: 9px;
  font-size: 14px;
}

.footer p {
  padding-bottom: 0 !important;
}

.hidden {
  display:none!important
}

.footer a {
  color:#047b9c
}

.footer a:focus,a:hover {
  color:#005a70;text-decoration:none
}

.footer ul {
  margin:0;
  list-style-type:none;
  display: inline-block;
  align-items: center;
  flex-flow: wrap;
  text-align: center;
  justify-content: center;
}

.footer li {
  margin-right:1em;
  padding-right:1em;
  border-right:1px solid #000;
  float: left;
}

.second-sample-text-color
{
  color: red;
}

@media (max-width:979px) {
.footer li {
  margin-bottom:12px
}

}

.footer .copyright {
  margin:0;
  display: inline-block;
    vertical-align: middle;
    padding-bottom: 9px;
}

@media (max-width:979px) {
.footer .copyright {
  text-align:center
}

}


img.breadCrumbImage, img.warningImage
{
  vertical-align: top;
}

.pe-icon--sortable-18 {
  background-image: url("sortable-18.svg") !important;
 }

 .pe-icon--sort-up-18 {
  background-image: url("sort-up-18.svg")  !important;
 }

 .pe-icon--sort-down-18 {
  background-image: url("sort-down-18.svg")  !important;
 }

 /* .pe-icon--dropdown-open-sm-18 {
  background-image: url("dropdown-open-sm-18.svg")  !important;
 } */

 .pe-icon--check-sm-18 {
  background-image: url("check-sm-18.svg")  !important;
  vertical-align: top  !important;
  background-repeat: no-repeat;
 }


 .modalHeader .pe-icon--btn {
  background-image: url("remove-lg-18.svg")  !important;
  background-repeat: no-repeat;
  margin-top: 1px;
  cursor: pointer;
  margin: auto;
 }

 .pe-icon--dropdown-open-sm-24
 {
  background-image: url("dropdown-open-sm-24.svg")  !important;
  vertical-align: top  !important;
  background-repeat: no-repeat;
 }

 .pe-label--bold {
  font-weight: bold;
 }

 .pe-bold {
  font-weight: bold;
 }

 .pe-row {
  display: flex;
  flex-direction: row;
 }

 .pe-col-6 {
  display: flex;
  flex: 1 1 0;
 }

 .pe-checkbox {
  text-wrap: nowrap;
 }

 .pe-checkbox *{
  display: inline;
 }

 .pe-checkbox input{
  cursor: pointer;
 }